export default function menuDescription ({items, currentItem, description}) {
  // Set description of active item
  initializeDescription()

  // Change description on hover
  $(items).hover(function enter (evt) {
    render(getDescriptionOf(evt.target))
  }, function leave () {
    initializeDescription()
  })

  function render (descriptionText) {
    if (descriptionText == null) throw new Error('Undefined description')
    $(description).html(descriptionText)
  }

  function initializeDescription () {
    render(getDescriptionOf(currentItem) || '')
  }

  function getDescriptionOf (element) {
    return $(element).data('description')
  }
}
