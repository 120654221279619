import contact from './lib/contact'
import rebox from './lib/rebox'
import { MegaMenuView } from './lib/megaMenu'
import menuDescription from './lib/menu-description'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  contact('#video-contact-form')
  rebox('.instanode-headstart-thumbimage a')
  toggleBelowFold()
  scrollSpy('.header-nav-sub:visible', { offset: 0 })
  smoothScroll('a[href^="#"]', { offset: 0 })
  menuDescription({
    items: '.header-nav-sub-list li > a[data-description]',
    currentItem: '.header-nav-sub-list li.current > a[data-description]',
    description: '.header-nav-sub-description'
  })
})

// Widget Anmeldung
$(document).ready(function () {
  if ($(window).width() <= 768) {
    $('.popuptext').hide()
    $('.widget-wrapper').click(function () {
      window.open(
        'https://www.ms-klinik.de/de/patienten-besucher/ihr-aufenthalt/infos-zur-anmeldung.html',
        '_blank'
      )
    })
  } else {
    $('.widget-wrapper').hover(function () {
      $('.widget-contact, .popuptext').toggleClass('active')
    })
  }
})

// Widget Veranstaltung
$(document).ready(function () {
  if ($(window).width() <= 768) {
    $('.popupevent').hide()
    $('.widget-wrapper-event').click(function () {
      window.open(
        'https://www.ms-klinik.de/de/klinik/veranstaltungen.html',
        '_blank'
      )
    })
  } else {
    $('.widget-wrapper-event').hover(function () {
      $('.widget-contact-event, .popupevent').toggleClass('active')
    })
  }
})

// Widget Covid
$(document).ready(function () {
  if ($(window).width() <= 768) {
    $('.popupcovid').hide()
    $('.widget-wrapper-covid').click(function () {
      window.open(
        'https://www.ms-klinik.de/de/klinik/veranstaltungen.html',
        '_blank'
      )
    })
  } else {
    $('.widget-wrapper-covid').hover(function () {
      $('.widget-covid, .popupcovid').toggleClass('active')
    })
  }
})

// Videosprechstunde Formluar Overlay
$(document).ready(function () {
  $('.video').on('click', function () {
    $('#video-form').show()
    $('#video-form').addClass('active')
  })
})

$(document).ready(function () {
  const modal = document.getElementById('video-form')
  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = 'none'
    } else if (event.target === modal) {
      modal.style.display = 'none'
    }
  }
})

function megaMenu () {
  const mouseInsideNav = function (target) {
    const isNav = $(target).closest('.header-nav').length > 0
    const isNavItem = $(target).closest('.header-nav > ul').length > 0

    if (isNav && !isNavItem) {
      return true
    }
  }

  window.MainMenuView = new MegaMenuView({
    el: '.header-nav-list .level-1',
    mega: '.header-nav-sub',
    mouseInside: mouseInsideNav
  })
}

$('document').ready(function () {
  const desktop = window.matchMedia('(min-width: 1024px)')
  if (desktop.matches) {
    megaMenu()
  }
})
