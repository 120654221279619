/* global $, _, Backbone */

const DELAY_SHOW = 200
const DELAY_HIDE = 0

export const MegaMenuView = Backbone.View.extend({
  initialize: function (options) {
    this.mouseInside = options.mouseInside

    this.$mega = $(options.mega)

    this.$el.on('mouseover', _.bind(this.onHoverHandler, this))

    $('body').on('mouseover', _.bind(this.onCancelShow, this))
    $('body').on('mouseover', _.bind(this.onHide, this))
    $('body').on('mouseover', _.bind(this.onCancelHide, this))
  },

  onHoverHandler: function (e) {
    const onShow = _.bind(this.onShow, this)
    this.$element = $(e.currentTarget)
    onShow()
  },

  onShow: function () {
    const showMega = _.bind(this.showMega, this)

    window.clearTimeout(this.showTimeout)
    this.$mega.hide()
    this.$element.find(this.$mega).show()
    this.showTimeout = window.setTimeout(showMega, DELAY_SHOW)
  },

  onCancelShow: function (evt) {
    if (this.visible) {
      return
    }

    if ($(evt.target).closest(this.$el).length) {
      return
    }

    window.clearTimeout(this.showTimeout)
  },

  onHide: function () {
    const hideMega = _.bind(this.hideMega, this)

    window.clearTimeout(this.hideTimeout)
    this.hideTimeout = window.setTimeout(hideMega, DELAY_HIDE)
  },

  onCancelHide: function (evt) {
    const isMega = $(evt.target).closest(this.$mega).length > 0
    const isMenu = $(evt.target).closest(this.$el).length > 0

    if (isMega || isMenu || this.mouseInside(evt.target)) {
      window.clearTimeout(this.hideTimeout)
    }
  },

  showMega: function () {
    if (this.visible && !this.$element) {
      return
    }

    window.clearTimeout(this.hideTimeout)
    this.$el.removeClass('show')
    this.$element.addClass('show')
    this.visible = true
  },

  hideMega: function () {
    if (!this.visible) {
      return
    }

    window.clearTimeout(this.showTimeout)
    this.$el.removeClass('show')
    this.$mega.hide()
    this.visible = false
  }
})
